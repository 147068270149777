import { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

import useStyles from './styles';

const sizes = {
	S: 25,
	M: 50,
	L: 75,
};

export const Loader = ({ size, local, isAuthenticated, currentLocation }) => {
	const classes = useStyles();

	if (local) {
		return <CircularProgress size={sizes[size]} />;
	}

	return (
		<Box className={classes.container}>
			<CircularProgress size={sizes[size]} />
		</Box>
	);
};

Loader.propTypes = {
	size: PropTypes.string,
	local: PropTypes.bool,
	isAuthenticated: PropTypes.bool,
	currentLocation: PropTypes.string,
};

Loader.defaultProps = {
	size: 'M',
	local: false,
};
