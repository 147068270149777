import React from 'react';

import StandardButton from '../../../Components/Shared/Buttons/StandardButton';
import Section from '../../../Components/Shared/Section';

// Styles
import useStyles from './styles';

function Training() {
	const classes = useStyles();

	const handleContactExperts = () => {
		window.open(
			'https://calendly.com/enaia-web-app/training-session',
			'_blank'
		);
	};

	return (
		<div className={classes.trainingContainer}>
			<Section title="Go deeper with hands-on training">
				<p style={{ marginBottom: '15px' }}>
					Build machine learning with the help of certified experts.
					<br />
					Let's get in touch with our experts.
				</p>
			</Section>
			<StandardButton
				value="Contact experts"
				handleClick={handleContactExperts}
			/>
		</div>
	);
}

export default Training;
