import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Components
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
	Avatar,
	Badge,
	CssBaseline,
	Grid,
	IconButton,
	Skeleton,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { Box } from '@mui/system';
import DropdownMenu from '../../Shared/DropdownMenu/DropdownMenu';

// Styles
import useStyles from './styles';
import styles from './styles.module.css';
import { measures } from '../../../consts/sizes';

// Images
import Logo from '../../../assets/media/img/logo/enaia_imagotipoAPP.png';
import { CustomTooltip } from '../../Shared/CustomTooltip';

const theme = createTheme();

function TopMenu({ handleSignOut, setIsTopMenu }) {
	const navigate = useNavigate();

	const classes = useStyles();

	const organizationInfoState = useSelector((state) => state.organization.info);

	const [hasNotifications, setHasNotifications] = useState(false);

	useEffect(() => {
		setIsTopMenu(true);
	}, []);

	const handleHasNotifications = () => {
		setHasNotifications(!hasNotifications);
	};

	// const handleSelectOrganizationProject = () => {
	// 	setOpenSelectOrganizationProjectModal(!openSelectOrganizationProjectModal);
	// };

	// const handleSelectCurrentOrganization = (organization) => {
	// 	dispatch(SET_CURRENT_ORGANIZATION(organization));
	// 	window.localStorage.setItem('currentOrganizationId', organization);
	// };

	// const handleSelectCurrentProject = (project) => {
	// 	dispatch(SET_CURRENT_PROJECT(project));
	// };

	// const handleSelectPersonalOrganization = () => {
	// 	dispatch(SET_CURRENT_PERSONAL_ORGANIZATION());
	// };

	// const handleCreateOrganization = () => {
	// 	dispatch(CREATE_ORGANIZATION());
	// };

	// const handleCreateProject = () => {
	// 	dispatch(CREATE_PROJECT(currentorganizationInfoState.id));
	// };

	return (
		<ThemeProvider theme={theme}>
			<Grid
				container
				component="main"
				id="top-menu"
				className={classes.mainContainer}
				px={{
					xs: 2,
					sm: 4,
					md: measures.mdGeneralMargin,
					lg: measures.lgGeneralMargin,
				}}
				// sx={{'&>*': {outline: '1px solid red'}}}
			>
				<CssBaseline />
				{/* COMPANY SELECTOR SM & MD */}
				<Grid
					item
					xs={12}
					md={2}
					sx={{
						justifyContent: {
							xs: 'center',
							md: 'flex-start',
						},
						cursor: 'pointer',
						display: 'flex',
						alignItems: 'center',
					}}
					className={styles.logo}
					onClick={() => navigate('/dashboard')}
				>
					<div
						style={{
							marginLeft: '6px',
							display: 'flex',
							flexDirection: 'column',
							lineHeight: '1.2rem',
						}}
					>
						<div>{process.env.REACT_APP_APP_NAME}</div>
					</div>
					{/* <Logo
						style={{ maxHeight: '5vh' }}
						onClick={() => navigate('/dashboard')}
					/> */}
				</Grid>
				<Grid
					item
					xs={12}
					sm={8}
					md={8}
					sx={{
						display: 'flex',
						justifyContent: {
							xs: 'center',
							sm: 'left',
							md: 'center',
						},
						marginTop: { xs: '6px' },
						marginBottom: { xs: '12px' },
					}}
				>
					<div className={classes.companyNameContainer}>
						<div className={classes.companyLogo}>
							{organizationInfoState !== null && (
								<Avatar
									alt="Organization logo"
									src={
										organizationInfoState.logo
											? organizationInfoState.logo['download_url']
											: null
									}
									variant="circle"
								/>
							)}
						</div>
						<Box
							sx={{
								fontSize: { xs: '1.2rem', md: '2rem' },
								display: 'block',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{(organizationInfoState !== null &&
								organizationInfoState.name) || (
								<Skeleton
									animation="wave"
									variant="text"
									style={{ height: '24px', width: '150px' }}
								/>
							)}
						</Box>
					</div>
				</Grid>
				<Grid
					item
					xs={12}
					sm={4}
					md={2}
					display={{ md: 'flex', sm: 'flex', xs: 'flex' }}
					sx={{
						justifyContent: { xs: 'center', sm: 'flex-end' },
						alignItems: 'center',
						gap: 3,
					}}
				>
					<IconButton onClick={() => navigate('/settings/user')}>
						<CustomTooltip title="My Account">
							<PersonIcon color="action" />
						</CustomTooltip>
					</IconButton>
					<IconButton onClick={() => navigate('/settings/organization')}>
						<CustomTooltip title="Organization">
							<ApartmentRoundedIcon color="action" />
						</CustomTooltip>
					</IconButton>
					<IconButton onClick={() => handleSignOut()}>
						<CustomTooltip title="Logout">
							<ExitToAppIcon color="action" />
						</CustomTooltip>
					</IconButton>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

TopMenu.propTypes = {
	handleSignOut: PropTypes.func,
	setIsTopMenu: PropTypes.func,
};

export default TopMenu;

// OLD WITH ORGANIZATION AND PROJECT MODAL SELECTOR

/** 
return (
  <ThemeProvider theme={theme}>
    <Grid
      container
      component="main"
      id="top-menu"
      className={classes.mainContainer}
      px={{
        xs: 2,
        sm: 4,
        md: measures.mdGeneralMargin,
        lg: measures.lgGeneralMargin,
      }}
    >
      <CssBaseline />
      // COMPANY SELECTOR XS 
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        display={{ md: 'none', sm: 'none', xs: 'flex' }}
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          '&:hover': {
            // outline: '1px solid black',
          },
        }}
      >
        {organizationInfoState !== null && organizationInfoState.name}
        // <MenuIcon color="action" /> 
      </Grid>
      // COMPANY SELECTOR SM & MD 
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        display={{ md: 'flex', sm: 'flex', xs: 'none' }}
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          '&:hover': {
            // outline: '1px solid black',
          },
        }}
      >
         <button
          type="button"
          // onClick={handleSelectOrganizationProject}
          className={classes.organizationProjectContainer}
        >  
        <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
          {organizationInfoState !== null && organizationInfoState.name}
          {/* {!organizationIsLoading ||
            Object.keys(currentorganizationInfoState).length !== 0 ? (
              <p className={styles.text_hover_blue}>
                {currentorganizationInfoState !== {}
                  ? currentorganizationInfoState.name
                  : ''}
              </p>
            ) : (
              <Skeleton
                animation="wave"
                variant="text"
                style={{ height: '24px', minWidth: '75px' }}
              />
            )}
            /
            {!projectIsLoading ||
            (currentProjectState &&
              Object.keys(currentProjectState).length !== 0) ? (
              <p className={styles.text_hover_blue}>
                {currentProjectState && currentProjectState.name
                  ? currentProjectState.name
                  : ''}
              </p>
            ) : (
              <Skeleton
                animation="wave"
                variant="text"
                style={{ height: '24px', minWidth: '75px' }}
              />
            )} 
        </div>
         <ArrowDropDownRoundedIcon
            fontSize="large"
            className={styles.fill_blue_hover}
          /> 
         </button> 
      </Grid>
      <Grid item xs={false} sm={false} md={2} />
      <Grid
        item
        xs={6}
        sm={6}
        md={4}
        display={{ md: 'flex', sm: 'flex', xs: 'flex' }}
        sx={{
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
         {hasNotifications ? (
          <IconButton onClick={handleHasNotifications}>
            <NotificationsIcon color="action" />
          </IconButton>
        ) : (
          <IconButton onClick={() => handleHasNotifications()}>
            <Badge badgeContent={4} color="primary">
              <NotificationImportantIcon color="action" />
            </Badge>
          </IconButton>
        )} 
        <IconButton onClick={() => navigate('/settings/user')}>
          <PersonIcon color="action" />
        </IconButton>
        <IconButton onClick={() => navigate('/settings/organization')}>
          <ApartmentRoundedIcon color="action" />
        </IconButton>
         <IconButton onClick={(e) => setSettingsAnchorEl(e.currentTarget)}>
          <SettingsIcon color="action" />
        </IconButton> 
         <DropdownMenu
          menuItems={[
            {
              title: 'Project settings',
              link: '/settings/project',
            },
            {
              title: 'Organization settings',
              link: '/settings/organization',
            },
          ]}
          anchorEl={settingsAnchorEl}
          setAnchorEl={setSettingsAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        /> 
        <IconButton onClick={() => handleSignOut()}>
          <ExitToAppIcon color="action" />
        </IconButton>
      </Grid>
    </Grid>

    // SELECT ORGANIZATION/PROJECT MODAL 
     <StandardModal
      open={openSelectOrganizationProjectModal}
      setOpen={setOpenSelectOrganizationProjectModal}
      content={
        <div className={classes.selectOrganizationProjectContainer}>
          <div className={classes.selectOrganizationProjectRow}>
            <div>1. Select organization</div>
            <div>2. Select project</div>
            <div className={styles.remove_btn_bg}>
              <StandardButton
                handleClick={handleCreateProject}
                value="Create project"
              />
            </div>
          </div>
          <div className={classes.selectOrganizationProjectRow}>
            <div className={`${classes.selectOrganizationColumn}`}>
              <StandardButton
                type="textButton"
                handleClick={handleCreateOrganization}
                value="+ Create organization"
              />
              <button
                key={uuidv4()}
                id="PERSONAL"
                type="button"
                className={`${classes.selectButton} ${
                  currentorganizationInfoState &&
                  currentorganizationInfoState.name &&
                  currentorganizationInfoState.name === 'PERSONAL' &&
                  classes.selectedOrganizationProject
                }`}
                onClick={handleSelectPersonalOrganization}
              >
                <ApartmentRoundedIcon />
                <div className={styles.wrap_overflow_text}>Personal</div>
              </button>
              {organizationsState &&
                organizationsState.length > 0 &&
                organizationsState.map((organization) => (
                  <button
                    key={organization.id}
                    type="button"
                    className={`${classes.selectButton} ${
                      organization.id === currentorganizationInfoState.id &&
                      classes.selectedOrganizationProject
                    }`}
                    onClick={() =>
                      handleSelectCurrentOrganization(organization)
                    }
                  >
                    <ApartmentRoundedIcon />
                    <div className={styles.wrap_overflow_text}>
                      {organization.name}
                    </div>
                  </button>
                ))}
            </div>
            <div
              className={`${classes.selectProjectColumn} ${classes.projectContainer}`}
            >
              <StandardButton
                type="textButton"
                handleClick={handleCreateProject}
                value="+ Create project"
                sx={{
                  outline: 'none',
                }}
              />
              {projectIsLoading ? (
                <>
                  <Skeleton
                    variant="text"
                    style={{ height: '24px', width: '200px' }}
                  />
                  <Skeleton
                    variant="text"
                    style={{ height: '24px', width: '200px' }}
                  />
                  <Skeleton
                    variant="text"
                    style={{ height: '24px', width: '200px' }}
                  />
                  <Skeleton
                    variant="text"
                    style={{ height: '24px', width: '200px' }}
                  />
                </>
              ) : (
                projectsState &&
                projectsState.length > 0 &&
                projectsState.map((project) => (
                  <button
                    key={project.id}
                    type="button"
                    className={`${classes.selectButton} ${
                      classes.projectSelectButton
                    } ${
                      project.id === currentProjectState.id &&
                      classes.selectedOrganizationProject
                    }`}
                    onClick={() => handleSelectCurrentProject(project)}
                  >
                    <HexagonRoundedIcon
                      fontSize="x-small"
                      className={classes.iconProject}
                    />
                    <div className={styles.wrap_overflow_text}>
                      {project.name}
                    </div>
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
      }
      actions={
        <StandardButton
          handleClick={() =>
            setOpenSelectOrganizationProjectModal(
              !openSelectOrganizationProjectModal
            )
          }
          close
          value="Close"
        />
      }
    /> 
  </ThemeProvider>
); */
