import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Manage roles & permissions
import { HasAccess } from '@permify/react-role';

// Components
import { Grid } from '@mui/material';
import CardItem from '../../Components/Shared/CardItem';
import Tasks from './Tasks';
import StandardButton from '../../Components/Shared/Buttons/StandardButton';
import { CreateTaskDialog } from './components/CreateTaskDialog';

// Consts
import { measures } from '../../consts/sizes';
import { defaultRoles } from '../../consts/rolesAndPermissions';

// Redux
import { GET_TASKS } from '../../redux/tasks.slice';
import { EmailVerification } from '../EmailVerification';

function Dashboard() {
	const dispatch = useDispatch();

	const tasksState = useSelector((state) => state.tasks);
	const organizationState = useSelector((state) => state.organization);
	const userState = useSelector((state) => state.user);
	const {
		accessToken,
		isVerified,
		isLoading: userIsLoading,
	} = useSelector((state) => state.user);

	const [openCreateTaskModal, setOpenCreateTaskModal] = useState(false);
	const [openIsVerifiedModal, setOpenIsVerifiedModal] = useState(false);

	useEffect(() => {
		if (!isVerified) setOpenIsVerifiedModal(true);
	}, [isVerified]);

	useEffect(() => {
		// Refresh tasks list if tasks is empty (probably some tasks are creating)
		if (
			tasksState.length === 0 &&
			userState.id !== '' &&
			organizationState.info.id !== ''
		) {
			dispatch(GET_TASKS({ accessToken, dispatch }));
		}
	}, []);

	useEffect(() => {
		if (!tasksState.isLoading && tasksState.tasks.length === 0)
			dispatch(GET_TASKS({ accessToken, dispatch }));
		// if (!userState.id) dispatch(GET_USER({ accessToken, dispatch, navigate }));
	}, [accessToken]);

	const handleCreateTask = () => {
		setOpenCreateTaskModal(true);
	};

	return (
		<>
			<Grid
				container
				component="main"
				sx={{
					px: {
						xs: 1,
						sm: 4,
						md: measures.mdGeneralMargin,
						lg: measures.lgGeneralMargin,
					},
					display: 'flex',
					alignItems: 'flex-center',
				}}
				spacing={2}
			>
				{/** FIRST ROW */}

				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'flex-center',
					}}
				>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						title="Tasks"
						titlelink={
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<HasAccess
									roles={defaultRoles}
									permissions="task.create"
									renderAuthFailed={
										<StandardButton type="disabled" value="Create task" />
									}
								>
									<StandardButton
										value="Create task"
										handleClick={handleCreateTask}
									/>
								</HasAccess>
							</div>
						}
					>
						<Tasks />
					</CardItem>
				</Grid>

				{/** SECOND ROW */}

				{/* <Grid
					container
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'flex-center',
						marginLeft: 0,
					}}
					spacing={2}
				>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={4}
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'flex-center',
						}}
					>
						<CardItem
							elevation={measures.cardItemElevation}
							sx={{
								display: 'flex',
								padding: 1,
							}}
							title="Training"
							titleicon={<ChangeHistoryIcon />}
						>
							<Training />
						</CardItem>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={4}
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'flex-center',
						}}
					>
						<CardItem
							elevation={measures.cardItemElevation}
							sx={{
								display: 'flex',
								padding: 1,
							}}
							title="News"
							titleicon={<NewspaperIcon />}
						>
							<News />
						</CardItem>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={4}
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'flex-center',
						}}
					>
						<CardItem
							elevation={measures.cardItemElevation}
							sx={{
								display: 'flex',
								padding: 1,
							}}
							title="Support"
							titleicon={<NewspaperIcon />}
						>
							<Support />
						</CardItem>
					</Grid>
				</Grid> */}
			</Grid>
			{openCreateTaskModal && (
				<CreateTaskDialog
					parentOpen={openCreateTaskModal}
					onClose={setOpenCreateTaskModal}
				/>
			)}
			{openIsVerifiedModal && (
				<EmailVerification
					open={openIsVerifiedModal}
					setOpen={setOpenIsVerifiedModal}
				/>
			)}
		</>
	);
}

export default Dashboard;

/* TEST QUERY BUILDER 
<Grid
  item
  xs={12}
  sx={{
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-center',
  }}
>
  <CardItem
    elevation={measures.cardItemElevation}
    sx={{
      display: 'flex',
      padding: 1,
    }}
    title="Training"
    titleicon={<ChangeHistoryIcon />}
  >
    <DemoQueryBuilder />
  </CardItem>
</Grid>
*/

/* CLOUD STATUS

<CardItem
  elevation={measures.cardItemElevation}
  sx={{
    width: '100%',
    display: 'flex',
    padding: 1,
    marginBottom: 3,
  }}
  title="Cloud status"
  titleicon={<CloudQueueIcon />}
>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <SignalWifiStatusbar4BarIcon
      style={{ color: colors.lightGreen }}
    />
    <p style={{ margin: '0 0 0 12px', padding: 0 }}>
      All systems operational
    </p>
  </div>
</CardItem> */

/* FREE CREDIT

<CardItem
  elevation={measures.cardItemElevation}
  sx={{
    display: 'flex',
    padding: 1,
    marginBottom: 3,
  }}
  title="Free credit"
  titleicon={<CelebrationIcon />}
>
  <FreeCredit />
</CardItem> */

/* DOCUMENTATION

<CardItem
elevation={measures.cardItemElevation}
sx={{
  width: '100%',
  display: 'flex',
  padding: 1,
  marginBottom: 3,
}}
title="Documentation"
titleicon={<MenuBookIcon />}
>
  <Documentation />
</CardItem> */
