import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import requestFactory from '../services/request.factory';
import { GET_TASK_PERMISSIONS } from './tasks.slice';

// CREATE REQUESTS

export const CREATE_ORGANIZATION = createAsyncThunk(
	'organization/CREATE_ORGANIZATION',
	async ({ accessToken, dispatch, data, navigate }) => {
		console.log('organization/CREATE_ORGANIZATION');
		if (data.address !== '') {
			const res = await requestFactory({
				type: 'POST',
				url: '/organizations',
				data,
				accessToken,
				dispatch,
			});

			if (res) return res;
		}
		navigate('/create-organization');
	}
);

// GET REQUESTS

export const GET_ORGANIZATION = createAsyncThunk(
	'organization/GET_ORGANIZATION',
	async ({ accessToken, dispatch, navigate }) => {
		console.log('organization/GET_ORGANIZATION');
		const res = await requestFactory({
			type: 'GET',
			url: '/myaccount/organization',
			accessToken,
			dispatch,
		});

		if (res) return res;
		navigate('/create-organization');
	}
);

export const GET_ORGANIZATION_FILE = createAsyncThunk(
	'organization/GET_ORGANIZATION_FILE',
	async ({ organizationId, fileId, accessToken, dispatch }) => {
		console.log('organization/GET_ORGANIZATION_FILE');
		const res = await requestFactory({
			type: 'GET',
			url: `/organizations/${organizationId}/files/${fileId}`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_ORGANIZATION_USERS = createAsyncThunk(
	'organization/GET_ORGANIZATION_USERS',
	async ({ organizationId, accessToken, page = 1, perPage = 50, dispatch }) => {
		console.log('organization/GET_ORGANIZATION_USERS');
		const res = await requestFactory({
			type: 'GET',
			url: `/organizations/${organizationId}/users?page=${page}&per_page=${perPage}&total_count=true`,
			accessToken,
			dispatch,
		});

		if (res && res.data && res.data.length > 0) {
			return Promise.all(
				res.data.map((user) =>
					requestFactory({
						type: 'GET',
						url: `/organizations/${organizationId}/users/${user.uuid}`,
						accessToken,
						dispatch,
					})
				)
			).then((result) => [result, res.total_count]);
		}
	}
);

export const GET_ORGANIZATION_USER_BY_ID = createAsyncThunk(
	'organization/GET_ORGANIZATION_USER_BY_ID',
	async ({ organizationId, accessToken, userId, dispatch }) => {
		console.log('organization/GET_ORGANIZATION_USER_BY_ID');
		const res = await requestFactory({
			type: 'GET',
			url: `/organizations/${organizationId}/users/${userId}`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_ORGANIZATION_SUBSCRIPTION = createAsyncThunk(
	'organization/GET_ORGANIZATION_SUBSCRIPTION',
	async ({ organizationId, accessToken, dispatch }) => {
		console.log('organization/GET_ORGANIZATION_SUBSCRIPTION');
		const res = await requestFactory({
			type: 'GET',
			url: `/organizations/${organizationId}/subscription`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_ORGANIZATION_COLLABORATORS = createAsyncThunk(
	'organization/GET_ORGANIZATION_COLLABORATORS',
	async ({ organizationId, accessToken, page = 1, perPage = 5, dispatch }) => {
		console.log('organization/GET_ORGANIZATION_COLLABORATORS');
		const res = await requestFactory({
			type: 'GET',
			url: `/organizations/${organizationId}/collaborators?page=${page}&per_page=${perPage}&total_count=true`,
			accessToken,
			dispatch,
		});

		if (res && res.data && res.data.length > 0) {
			return Promise.all(
				res.data.map((collaborator) =>
					requestFactory({
						type: 'GET',
						url: `/organizations/${organizationId}/collaborators/${collaborator.id}`,
						accessToken,
						dispatch,
					})
				)
			).then((result) => [result, res.total_count]);
		}

		return [[], res.total_count];
	}
);

export const GET_ORGANIZATION_ROLES = createAsyncThunk(
	'organization/GET_ORGANIZATION_ROLES',
	async ({ organizationId, accessToken, dispatch }) => {
		console.log('organization/GET_ORGANIZATION_ROLES');
		const res = await requestFactory({
			type: 'GET',
			url: `/organizations/${organizationId}/roles`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_ORGANIZATION_APPS = createAsyncThunk(
	'organization/GET_ORGANIZATION_APPS',
	async ({ organizationId, accessToken, dispatch }) => {
		console.log('organization/GET_ORGANIZATION_APPS');
		const res = await requestFactory({
			type: 'GET',
			url: `/organizations/${organizationId}/apps`,
			accessToken,
			dispatch,
		});

		const tmpApps = res.filter(
			(app) =>
				app.name !== 'inference_service' &&
				app.name !== 'cl_service' &&
				app.name !== 'monitoring_service' &&
				app.name !== 'al_service'
		);

		if (res && res.length > 0) {
			return Promise.all(
				tmpApps.map(async (app) => {
					const tmp = await requestFactory({
						type: 'GET',
						url: `/organizations/${organizationId}/apps/${app.id}/api-key`,
						accessToken,
						dispatch,
					});
					return {
						...app,
						apiKey: tmp.token,
						scopes: tmp.scopes,
						expireAt: tmp.expire_at,
					};
				})
			);
		}
	}
);

// POST REQUESTS

export const CREATE_ORGANIZATION_FILE = createAsyncThunk(
	'organization/CREATE_ORGANIZATION_FILE',
	async ({ organizationId, file, accessToken, dispatch }) => {
		console.log('organization/CREATE_ORGANIZATION_FILE');

		const { name: filename, size } = file;

		const res = await requestFactory({
			type: 'POST',
			url: `/organizations/${organizationId}/files`,
			accessToken,
			data: { filename, size },
			dispatch,
		}).then((result) => result);

		const formData = new FormData();
		const { url, fields } = res['upload_url'];

		Object.keys(fields).forEach((key) => {
			formData.append(key, fields[key]);
		});
		formData.append('file', file);

		const awsRes = await fetch(url, {
			method: 'POST',
			body: formData,
		}).then((awsResponse) => awsResponse);

		if (awsRes && awsRes.ok) return res;
	}
);

export const CREATE_ORGANIZATION_USER_PERMISSION = createAsyncThunk(
	'organization/CREATE_ORGANIZATION_USER_PERMISSION',
	async ({
		organizationId,
		userId,
		selectedPermissions,
		accessToken,
		dispatch,
		taskId,
	}) => {
		console.log('organization/CREATE_ORGANIZATION_USER_PERMISSION');
		await requestFactory({
			type: 'POST',
			url: `/organizations/${organizationId}/users/${userId}/permissions`,
			accessToken,
			data: selectedPermissions,
			dispatch,
		}).then(
			dispatch(
				GET_TASK_PERMISSIONS({
					taskId,
					accessToken,
					dispatch,
				})
			)
		);
	}
);

export const CREATE_ORGANIZATION_ROLE_PERMISSION = createAsyncThunk(
	'organization/CREATE_ORGANIZATION_ROLE_PERMISSION',
	async ({
		organizationId,
		roleId,
		selectedPermissions,
		accessToken,
		dispatch,
		taskId,
	}) => {
		console.log('organization/CREATE_ORGANIZATION_ROLE_PERMISSION');
		await requestFactory({
			type: 'POST',
			url: `/organizations/${organizationId}/roles/${roleId}/permissions`,
			accessToken,
			data: selectedPermissions,
			dispatch,
		}).then(
			dispatch(
				GET_TASK_PERMISSIONS({
					taskId,
					accessToken,
					dispatch,
				})
			)
		);
	}
);

// PUT REQUESTS

export const UPDATE_ORGANIZATION_INFO = createAsyncThunk(
	'organization/UPDATE_ORGANIZATION_INFO',
	async ({ organizationId, organizationInfo, accessToken, dispatch }) => {
		console.log('organization/UPDATE_ORGANIZATION_INFO');
		const res = await requestFactory({
			type: 'PUT',
			url: `/organizations/${organizationId}`,
			accessToken,
			data: organizationInfo,
			dispatch,
		});

		if (res) return res;
	}
);

export const UPDATE_ORGANIZATION_APP = createAsyncThunk(
	'organization/UPDATE_ORGANIZATION_APP',
	async ({ organizationId, appInfo, accessToken, appId, dispatch }) => {
		console.log('organization/UPDATE_ORGANIZATION_APP');
		const res1 = await requestFactory({
			type: 'PUT',
			url: `/organizations/${organizationId}/apps/${appId}`,
			accessToken,
			data: appInfo.client,
			dispatch,
		});
		const res2 = await requestFactory({
			type: 'PUT',
			url: `/organizations/${organizationId}/apps/${appId}/api-key`,
			accessToken,
			data: appInfo.apiKey,
			dispatch,
		});

		if (res1 && res2) return { ...res1, ...res2 };
	}
);

// DELETE

export const DELETE_ORGANIZATION_USER_ROLE = createAsyncThunk(
	'organization/DELETE_ORGANIZATION_USER_ROLE',
	async ({ organizationId, userId, selectedRoles, accessToken, dispatch }) => {
		console.log('organization/DELETE_ORGANIZATION_USER_ROLE');

		Promise.all(
			selectedRoles.map((roleId) =>
				requestFactory({
					type: 'DELETE',
					url: `/organizations/${organizationId}/users/${userId}/roles/${roleId}`,
					accessToken,
					dispatch,
				})
			)
		).then(() => GET_ORGANIZATION_USERS({ organizationId, accessToken }));
	}
);

export const DELETE_ORGANIZATION_USER = createAsyncThunk(
	'organization/DELETE_ORGANIZATION_USER',
	async ({ organizationId, userId, accessToken, dispatch }) => {
		console.log('organization/DELETE_ORGANIZATION_USER');

		await requestFactory({
			type: 'DELETE',
			url: `/organizations/${organizationId}/users/${userId}`,
			accessToken,
			dispatch,
		}).then(GET_ORGANIZATION_USERS({ organizationId, accessToken }));
	}
);

export const DELETE_ORGANIZATION_COLLABORATOR = createAsyncThunk(
	'organization/DELETE_ORGANIZATION_COLLABORATOR',
	async ({ organizationId, collaboratorId, accessToken, dispatch }) => {
		console.log('organization/DELETE_ORGANIZATION_COLLABORATOR');

		await requestFactory({
			type: 'DELETE',
			url: `/organizations/${organizationId}/collaborators/${collaboratorId}`,
			accessToken,
			dispatch,
		}).then(GET_ORGANIZATION_USERS({ organizationId, accessToken }));
	}
);

export const DELETE_ORGANIZATION_USER_PERMISSION = createAsyncThunk(
	'organization/DELETE_ORGANIZATION_USER_PERMISSION',
	async ({
		organizationId,
		userId,
		selectedPermissions,
		accessToken,
		dispatch,
	}) => {
		console.log('organization/DELETE_ORGANIZATION_USER_PERMISSION');

		await requestFactory({
			type: 'DELETE',
			url: `/organizations/${organizationId}/users/${userId}/permissions?action=${
				selectedPermissions.action
			}&allow=${selectedPermissions.allow}&resource_type=${
				selectedPermissions.resource_type
			}${
				selectedPermissions.resource_uuid !== undefined
					? `&resource_uuid=${selectedPermissions.resource_uuid}`
					: ''
			}`,
			accessToken,
			dispatch,
		});
	}
);

export const DELETE_ORGANIZATION_COLLABORATOR_PERMISSION = createAsyncThunk(
	'organization/DELETE_ORGANIZATION_COLLABORATOR_PERMISSION',
	async ({
		organizationId,
		collaboratorId,
		selectedPermissions,
		accessToken,
		dispatch,
	}) => {
		console.log('organization/DELETE_ORGANIZATION_COLLABORATOR_PERMISSION');

		await requestFactory({
			type: 'DELETE',
			url: `/organizations/${organizationId}/collaborators/${collaboratorId}/permissions?action=${
				selectedPermissions.action
			}&allow=${selectedPermissions.allow}&resource_type=${
				selectedPermissions.resource_type
			}${
				selectedPermissions.resource_uuid !== undefined
					? `&resource_uuid=${selectedPermissions.resource_uuid}`
					: ''
			}`,
			accessToken,
			dispatch,
		});
	}
);

export const DELETE_ORGANIZATION_ROLE = createAsyncThunk(
	'organization/DELETE_ORGANIZATION_ROLE',
	async ({ organizationId, roleId, accessToken, dispatch }) => {
		console.log('organization/DELETE_ORGANIZATION_ROLE');

		await requestFactory({
			type: 'DELETE',
			url: `/organizations/${organizationId}/roles/${roleId}`,
			accessToken,
			dispatch,
		});
	}
);

export const DELETE_ORGANIZATION_ROLE_PERMISSION = createAsyncThunk(
	'organization/DELETE_ORGANIZATION_ROLE_PERMISSION',
	async ({
		organizationId,
		roleId,
		selectedPermissions,
		accessToken,
		dispatch,
	}) => {
		console.log('organization/DELETE_ORGANIZATION_ROLE_PERMISSION');

		await requestFactory({
			type: 'DELETE',
			url: `/organizations/${organizationId}/roles/${roleId}/permissions?action=${
				selectedPermissions.action
			}&allow=${selectedPermissions.allow}&resource_type=${
				selectedPermissions.resource_type
			}${
				selectedPermissions.resource_uuid &&
				`&resource_uuid=${selectedPermissions.resource_uuid}`
			}`,
			accessToken,
			dispatch,
		});
	}
);

export const DELETE_ORGANIZATION_APP = createAsyncThunk(
	'organization/DELETE_ORGANIZATION_APP',
	async ({ organizationId, appId, accessToken, dispatch }) => {
		console.log('organization/DELETE_ORGANIZATION_APP');

		await requestFactory({
			type: 'DELETE',
			url: `/organizations/${organizationId}/apps/${appId}`,
			accessToken,
			dispatch,
		}).then(GET_ORGANIZATION_APPS({ organizationId, accessToken }));
	}
);

export const organizationSlice = createSlice({
	name: 'organization',
	initialState: {
		isLoading: true,
		usersIsLoading: true,
		collaboratorsIsLoading: true,
		rolesIsLoading: true,
		appsIsLoading: true,
		info: {},
		subscription: {},
		users: {
			displayedUsers: [],
			totalUsers: null,
		},
		collaborators: {
			displayedCollaborators: [],
			totalCollaborators: null,
		},
		roles: [],
		apps: [],
	},
	reducers: {
		// SET_CURRENT_PERSONAL_ORGANIZATION: (state) => {
		// 	console.log('organization/SET_CURRENT_PERSONAL_ORGANIZATION');
		// 	state.currentOrganization = { id: '', name: 'PERSONAL' };
		// },
	},
	extraReducers: (builder) => {
		// GET

		builder.addCase(GET_ORGANIZATION.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(GET_ORGANIZATION.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.info = payload;
		});
		builder.addCase(GET_ORGANIZATION.rejected, (state) => {
			state.isLoading = true;
		});

		builder.addCase(GET_ORGANIZATION_USERS.pending, (state) => {
			state.usersIsLoading = true;
		});
		builder.addCase(GET_ORGANIZATION_USERS.fulfilled, (state, { payload }) => {
			state.usersIsLoading = false;
			const [users, totalCount] = payload;
			state.users.displayedUsers = users;
			state.users.totalUsers = totalCount;
		});
		builder.addCase(GET_ORGANIZATION_USERS.rejected, (state) => {
			state.usersIsLoading = true;
		});

		builder.addCase(GET_ORGANIZATION_SUBSCRIPTION.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(
			GET_ORGANIZATION_SUBSCRIPTION.fulfilled,
			(state, { payload }) => {
				state.isLoading = false;
				state.subscription = payload;
			}
		);
		builder.addCase(GET_ORGANIZATION_SUBSCRIPTION.rejected, (state) => {
			state.isLoading = true;
		});

		builder.addCase(GET_ORGANIZATION_COLLABORATORS.pending, (state) => {
			state.collaboratorsIsLoading = true;
		});
		builder.addCase(
			GET_ORGANIZATION_COLLABORATORS.fulfilled,
			(state, { payload }) => {
				state.collaboratorsIsLoading = false;
				const [collaborators, totalCount] = payload;
				state.collaborators.displayedCollaborators = collaborators;
				state.collaborators.totalCollaborators = totalCount;
			}
		);
		builder.addCase(GET_ORGANIZATION_COLLABORATORS.rejected, (state) => {
			state.collaboratorsIsLoading = true;
		});

		builder.addCase(GET_ORGANIZATION_ROLES.pending, (state) => {
			state.rolesIsLoading = true;
		});
		builder.addCase(GET_ORGANIZATION_ROLES.fulfilled, (state, { payload }) => {
			state.rolesIsLoading = false;
			state.roles = payload;
		});
		builder.addCase(GET_ORGANIZATION_ROLES.rejected, (state) => {
			state.rolesIsLoading = true;
		});

		builder.addCase(GET_ORGANIZATION_APPS.pending, (state) => {
			state.appsIsLoading = true;
		});
		builder.addCase(GET_ORGANIZATION_APPS.fulfilled, (state, { payload }) => {
			state.appsIsLoading = false;
			if (payload) state.apps = payload;
		});
		builder.addCase(GET_ORGANIZATION_APPS.rejected, (state) => {
			state.appsIsLoading = true;
		});

		// PUT

		builder.addCase(UPDATE_ORGANIZATION_INFO.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(
			UPDATE_ORGANIZATION_INFO.fulfilled,
			(state, { payload }) => {
				state.info = payload;
			}
		);
		builder.addCase(UPDATE_ORGANIZATION_INFO.rejected, (state) => {
			state.isLoading = true;
		});
	},
});

// Action creators are generated for each case reducer function
// export const { SET_CURRENT_PERSONAL_ORGANIZATION } =
// 	organizationSlice.actions;

export default organizationSlice.reducer;
